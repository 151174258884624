<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="new_user_id" :label="$t('message.user')">
        <select-user
            :size="'medium'"
            :placeholder="$t('message.user')"
            :id="form.new_user_id"
            :old_user="user_id"
            v-model="form.new_user_id"
        >
        </select-user>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="userDeleteClose">{{ $t('message.cancel') }}</el-button>
      <el-button @click="destroy()" type="primary" v-loading="loadingButton"
      >{{ $t('message.confirm') }}</el-button>
    </span>
  </div>
</template>

<script>
import selectUser from "@/components/inventory/select-deal-users";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    components: {
        selectUser,
    },
    props: {
      user_id:{
        default: null
      },
      message:{
        default: ''
      }
    },
    data() {
        return {
            form: {
              new_user_id: null,
            },
            rules: {
              new_user_id: [
                { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.user')}), trigger: 'blur' },
              ],
            }
        };
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    created() {},
    
    methods: {
        ...mapActions({
           checkUserData: "users/checkUserData",
        }),
        userDeleteClose() {
            this.$emit('userDeleteClose', false)
        },
        destroy() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.parent().listChanged();
                    this.checkUserData({ deletable_user_id: this.user_id, new_user_id: this.form.new_user_id })
                    .then(res => {
                        this.$alert(res);
                        this.$emit('userDeleteClose', true);
                    });
                }
            });
            
        },
    },
};
</script>


<style>
</style>
